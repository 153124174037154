.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background: #f9f9f9;
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-zone:hover {
  border-color: #999;
  background: #f0f0f0;
}

.drop-zone input[type="file"] {
  display: none;
}

.drop-zone p {
  margin: 0;
  color: #666;
}

.drop-zone i {
  font-size: 24px;
  margin-bottom: 10px;
  color: #666;
}

.drop-zone.drag-over {
  border-color: #2196f3;
  background: #e3f2fd;
}

.selected-files {
  margin-top: 10px;
  width: 100%;
}

.selected-files ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.selected-files li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 3px;
}

.selected-files li:hover {
  background: #f5f5f5;
}

.selected-files li span {
  cursor: pointer;
  color: #999;
}

.selected-files li span:hover {
  color: #ff0000;
}

/* Additional styles for the upload area */
.upload-area {
  position: relative;
  width: 100%;
}

.upload-area .fa-upload {
  font-size: 32px;
  margin-bottom: 15px;
  color: #666;
}

.upload-message {
  color: #666;
  margin: 10px 0;
}

/* Style for error messages */
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}